// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var navContainer = "C_bn";
export var deskAppear = "C_b6";
export var mobAppear = "C_b7";
export var closed = "C_b8";
export var opened = "C_b9";
export var menuItem = "C_br";
export var activeLink = "C_cb";
export var articleSourceWrapper = "C_6";
export var active = "C_bs";
export var dropdown = "C_bt";
export var dropdownContent = "C_bz";
export var isOpened = "C_bp";
export var isClosed = "C_bq";
export var dropBtn = "C_bC";
export var btnDropClose = "C_by";
export var btnDropOpen = "C_bD";
export var dropCont = "C_cc";
export var navOpenButton = "C_cd";
export var closedBtn = "C_cf";